<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5 mb-3">Create Bank</span>
				</v-card-title>
				<v-card-text>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense label="Bank Name*" hint="Eg: Development Bank of Singapore" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Bank Code*" hint="Eg: DBS" :rules="rules.code" :error-messages="errors.code" v-model="object.code"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Bank Number*" hint="Eg: 7171" :rules="rules.number" :error-messages="errors.number" v-model="object.number"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Swift Code" hint="Eg: DBSSSGSG" :rules="rules.swift_code" :error-messages="errors.swift_code" v-model="object.swift_code"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="BIC Code" hint="Eg: DBSSSGSGXXX" :rules="rules.bic_code" :error-messages="errors.bic_code" v-model="object.bic_code"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Account Number Length" hint="Use comma to separate multiple values if any. Eg: 10,12" :rules="rules.account_digits" :error-messages="errors.account_digits" v-model="object.account_digits"></v-text-field>
						</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text :disabled="loading" @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'BankCreateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                name: '',
                code: '',
                number: '',
                swift_code: '',
                bic_code: '',
                account_digits: ''
			},
			rules: {
                name: [
                    (value) => !!value || 'Name is required',
                    (value) => value && value.length >= 2 && value.length <= 100 || 'Name must be between 2 and 100 characters'
                ],
                code: [
                    (value) => !!value || 'Code is required',
                    (value) => value && value.length >= 2 && value.length <= 100 || 'Registered name must be between 2 and 100 characters'
                ],
                number: [
                    (value) => {
                        if(!value){
                            return 'Number is required'
                        }

                        if(value.length != 4){
                            return "Number must be 4 numeric value"
                        }
                        return true
                    }
                ],
                swift_code: [
                    (value) => {
                        if(value && value.length != 8){
                            return "Swift code must be 8 characters"
                        }
                        return true
                    }
                ],
                bic_code: [
                    (value) => {
                        if(value && value.length != 11){
                            return "BIC code must be 11 characters"
                        }
                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
            this.loading = false
            this.form.reset()
            this.form.resetValidation()
            this.object = { name: '', code: '', number: '', swift_code: '', bic_code: '', account_digits: '' }
            this.errors = {}
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object)

				this.$store.dispatch('epanel/bank/createBank', object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:{message:errors.message}
                    this.loading = false
                })
			}
		}
	}
}

</script>